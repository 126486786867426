<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Jenis Pengeluaran</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Data Jenis Pengeluaran</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Jenis Pengeluaran"
          class="mr-2"
          @click="onAddData"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="kode"
            header="kode"
            filterField="kode"
            style="min-width: 10rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="nama"
            header="nama pengeluaran"
            style="min-width: 15rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="tipe"
            header="tipe"
            style="min-width: 15rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="listTipe"
                :filter="true"
                optionValue="value"
                optionLabel="label"
                placeholder="Pilih Tipe"
                class="p-column-filter"
              >
              </Dropdown>
            </template>
          </Column>
          <Column
            header="Perkiraan Akun"
            filterField="coa_head_id"
            style="min-width: 15rem"
            sortable
            :showFilterMenu="false"
          >
            <template #body="slotProps">
               {{ slotProps.data.coa_item.kode }} - {{ slotProps.data.coa_item.nama }}
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit item' : 'Tambah item'"
      v-model:visible="dialog"
      class="p-overflow-hidden"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      modal
      maximizable
    >
      <form-jenis-pengeluaran
        :item="form"
        :loading="isLoadingSave"
        :list-tipe="listTipe"
        :list-perkiraan-akun="listPerkiraanAkun"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Item"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ form.name }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItem"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PerkiraanAkunService from '@/services/PerkiraanAkunService'
import JenisPengeluaranService from '@/services/JenisPengeluaranService'
import FormJenisPengeluaran from '@/components/master/FormJenisPengeluaran'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import { formatCurrency } from '@/helpers/index'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Jenis Pengeluaran',
  components: {
    FormJenisPengeluaran,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      jenisPengeluaranService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      listPerkiraanAkun:[],
      listTipe: [
        { value: 'operasional', label: 'Operasional' },
        { value: 'pendanaan', label: 'Pendanaan' },
        { value: 'investasi', label: 'Investasi' },
      ],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'kode',
        sortOrder: 'asc',
        filters: null,
      },
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
        tipe: { value: null, matchMode: FilterMatchMode.EQUALS },
        coa_item_id: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    }
  },
  created() {
    this.jenisPengeluaranService = new JenisPengeluaranService()
  },
  async mounted() {
    const perkiraanAkunService = new PerkiraanAkunService()
    await perkiraanAkunService
      .get()
      .then((res) => {
        this.listPerkiraanAkun = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Head Akun', this)
      })

    this.loadData()
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val)
    },
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.jenisPengeluaranService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Jenis Pengeluaran', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.form = { ...this.form }
      this.dialog = true
    },
    onAddData() {
      this.form = {
        id: 0,
        nama: null,
        tipe: null,
        coa_item_id: 0,
      }
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      if (data.id === 0) {
        this.jenisPengeluaranService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Jenis Pengeluaran',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Jenis Pengeluaran', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.jenisPengeluaranService
          .update(data.id, data)
          .then((res) => {
            if (res.data.status === 200) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Jenis Pengeluaran',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Jenis Pengeluaran', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteItem() {
      this.jenisPengeluaranService
        .delete(this.form.id)
        .then((res) => {
          if (res.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Jenis Pengeluaran',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Jenis Pengeluaran', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
}
</script>
